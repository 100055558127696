var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('strong',[_vm._v("Role Permission")]),_c('a-button',{staticClass:"btn btn-outline-primary ml-2",on:{"click":function($event){return _vm.sendToForm('Create')}}},[_vm._v("Tambah Data")])],1)])]),_c('div',{staticClass:"card-body"},[_c('a-table',{staticStyle:{"margin-left":"-26px","margin-right":"-26px","margin-top":"-21px"},attrs:{"columns":_vm.columns,"dataSource":_vm.data,"size":"small","customRow":_vm.customRow,"pagination":{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30', '50', '70', '80', '100', '200'],
        }},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                .toString()
                .split(
                  new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_c('span',[_vm._v(" "+_vm._s(text)+" ")])]]}},{key:"action",fn:function(text, record){return _c('span',{},[_c('a-icon',{staticClass:"text-warning",attrs:{"type":"edit"},on:{"click":function($event){return _vm.sendToForm('Update', record, record.id)}}}),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-icon',{staticClass:"text-danger",attrs:{"type":"delete"},on:{"click":function($event){return _vm.showDeleteConfirm(record)}}})],1)}},{key:"appList",fn:function(text, record){return _vm._l((record.appLists),function(data,index){return _c('a-tag',{key:index},[_vm._v(_vm._s(data.appName))])})}},{key:"currency",fn:function(text){return [_vm._v(" "+_vm._s(_vm.formatCurrency(text))+" ")]}}])})],1)]),_c('formModal',{key:_vm.componentKey,ref:"formModal",attrs:{"table":_vm.table}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }