<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-12">
            <strong>Role Permission</strong>
            <a-button
              class="btn btn-outline-primary ml-2"
              @click="sendToForm('Create')"
              >Tambah Data</a-button
            >
          </div>
        </div>
      </div>
      <div class="card-body">
        <a-table
          style="margin-left: -26px; margin-right: -26px; margin-top: -21px;"
          :columns="columns"
          :dataSource="data"
          size="small"
          :customRow="customRow"
          :pagination="{
            hideOnSinglePage: true,
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30', '50', '70', '80', '100', '200'],
          }"
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px;"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block;"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px;"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            >
              Search
            </a-button>
            <a-button
              size="small"
              style="width: 90px;"
              @click="() => handleReset(clearFilters)"
            >
              Reset
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>
              <span>
                {{ text }}
              </span>
            </template>
          </template>
          <span slot="action" slot-scope="text, record">
            <a-icon
              type="edit"
              class="text-warning"
              @click="sendToForm('Update', record, record.id)"
            />
            <a-divider type="vertical" />
            <a-icon
              type="delete"
              class="text-danger"
              @click="showDeleteConfirm(record)"
            />
          </span>
          <template slot="appList" slot-scope="text, record">
            <a-tag v-for="(data, index) in record.appLists" :key="index">{{
              data.appName
            }}</a-tag>
          </template>
          <template slot="currency" slot-scope="text">
            {{ formatCurrency(text) }}
          </template>
        </a-table>
      </div>
    </div>
    <formModal ref="formModal" :key="componentKey" :table="table" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'
import formModal from './roles_permission_form'

export default {
  components: {
    formModal,
  },
  data() {
    return {
      name: 'TablesAntdBasic',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      popinput: {
        password: '',
        re_password: '',
      },
      rowIndexGotSpan: [],
      columns: [
        {
          title: 'Action',
          key: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' },
        },
        {
          title: 'Role',
          dataIndex: 'rolename',
          customRender: (value, params, index) => {
            // console.log('index', index)
            this.rowIndexGotSpan = []
            const obj = {
              children: value,
              attrs: {},
            }
            // console.log('params', params)
            // var data = params.roleid
            // if (data !== null) {
            //   // console.log('this.data', this.data)
            //   var target = this.backupdata.filter((x) => x.roleid === data)
            //   console.log(data, target)
            //   if (target.length > 1) {
            //     // console.log('target.length', target.length)
            //     obj.attrs.rowSpan = target.length
            //     if (!this.rowIndexGotSpan.includes(data)) {
            //       this.rowIndexGotSpan.push(data)
            //     } else {
            //       obj.attrs.rowSpan = 1
            //     }
            //   }
            // } else {
            //   obj.attrs.rowSpan = 1
            // }
            if (params.rolename === undefined) {
              obj.attrs.rowSpan = 0
            // obj.attrs.colSpan = 0
            } else {
              obj.attrs.rowSpan = params.myrowspan
            }
            // console.log('obj', obj)
            return obj
          },
          // scopedSlots: {
          //   filterDropdown: 'filterDropdown',
          //   filterIcon: 'filterIcon',
          //   customRender: 'customRender',
          // },
          // onFilter: (value, record) =>
          //   record.rolename.toString().toLowerCase().includes(value.toLowerCase()),
          // onFilterDropdownVisibleChange: (visible) => {
          //   if (visible) {
          //     setTimeout(() => {
          //       this.searchInput.focus()
          //     }, 0)
          //   }
          // },
        },
        {
          title: 'Permission',
          dataIndex: 'permission_name',
          // customCell: (value, index) => {
          //   const obj = {
          //     children: value,
          //     attrs: {},
          //   }
          //   // console.log('value', value)
          //   // console.log('index', index)
          //   if (index === 2) {
          //     obj.attrs.rowSpan = 2
          //   }
          //   // These two are merged into above cell
          //   if (index === 3) {
          //     obj.attrs.rowSpan = 0
          //   }
          //   if (index === 4) {
          //     obj.attrs.colSpan = 0
          //   }
          //   console.log('permission_name', obj)
          //   return obj
          // },
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.permission_name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Role Type',
          dataIndex: 'role_type_name',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.role_type_name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Batas Bawah',
          dataIndex: 'batas_bawah',
          scopedSlots: { customRender: 'currency' },
          sorter: (a, b) => a.batas_bawah - b.batas_bawah,
        },
        {
          title: 'Batas Atas',
          dataIndex: 'batas_atas',
          scopedSlots: { customRender: 'currency' },
          sorter: (a, b) => a.batas_atas - b.batas_atas,
        },
      ],
      selectedidbpr: '',
      datapermission: [],
      table: 'rolepermission',
      form: this.$form.createForm(this, { name: 'Form ' + this.table }),
      data: [],
      backupdata: [],
      componentKey: 0,
    }
  },
  mounted: function () {
    this.getMaster()
  },
  methods: {
    findName(record) {
      if (record.appLists.length === 0) {
        return 'Tidak ada List app'
      } else {
        var tindex = record.appLists.findIndex(
          (x) => x.id === record.id_Default_App,
        )
        // console.log('tindex', tindex)
        return record.appLists[tindex].appName
      }
    },
    hide(index) {
      this.data[index].changePassPop = false
      this.popinput = {
        password: '',
        re_password: '',
      }
    },
    async changePassword(index) {
      if (this.popinput.password === this.popinput.re_password) {
        var fd = {
          id: this.data[index].id,
          password: this.popinput.password,
        }
        var res = await lou.customUrlPut('manage/rolepermission/changepassword', fd)
        if (res) {
          this.data[index].changePassPop = false
          lou.shownotif('Success', res.message, 'success')
          this.popinput = {
            password: '',
            re_password: '',
          }
        }
      } else {
        lou.shownotif('Not Valid!', 'Password tidak sama!')
      }
    },
    sendToForm(action, data = {}) {
      this.$refs.formModal.showModal(action, data)
    },
    async getMaster() {
      var res = await lou.customUrlGet('otorisasi/permission', false, true)
      var res1 = await lou.customUrlGet('otorisasi/roles', false, true)
      if (res) {
        this.datapermission = res.data
      }
      if (res1) {
        this.dataroles = res1.data
      }
      this.getData()
    },
    async getData() {
      var res = await lou.customUrlGet('otorisasi/rolepermission', false, true)
      if (res) {
        this.data = []
        var rowsdata = []
        res.data.forEach(element => {
          element.detail.forEach((el, index) => {
            var adddata = {
              rolename: '',
              roleid: '',
              myrowspan: 0,
              ...el,
            }
            if (index === 0) {
              adddata.rolename = element.role_name
              adddata.myrowspan = element.detail.length
            } else {
              delete adddata.rolename
              delete adddata.myrowspan
            }
            // adddata.rolename = index === 0 ? element.role_name : ''
            adddata.roleid = element.role_id
            adddata.role_id = element.role_id
            rowsdata.push(adddata)
          })
        })
        this.data = this.$g.clone(rowsdata)
        this.backupdata = this.$g.clone(rowsdata)
        // console.log('this.data', this.data)
        // res.data.forEach((element) => {
        //   // console.log('element', element)
        //   var findtargetuser = this.datapermission.findIndex(
        //     (x) => x.id === element.user_id,
        //   )
        //   var findtargetrole = this.dataroles.findIndex(
        //     (x) => x.id === element.role_id,
        //   )
        //   // console.log('findtargetuser', findtargetuser)
        //   var ndata = {
        //     manualrenderuser:
        //       findtargetuser !== -1
        //         ? this.datapermission[findtargetuser].name
        //         : 'Data user tidak ditemukan',
        //     manualrenderrole:
        //       findtargetrole !== -1
        //         ? this.dataroles[findtargetrole].name
        //         : 'Data role tidak ditemukan',
        //     ...element,
        //   }
        //   this.data.push(ndata)
        // })
      }
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete(
            'otorisasi/rolepermission/' + deldata.id, true, true,
          )
          if (response) {
            this.getData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            // this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>
