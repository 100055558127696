<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action === 'Create' ? 'Tambah ' : 'Edit ' + ' Role Permission'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
      :forceRender="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit()"
        >
          Simpan
        </a-button>
      </template>
      <div class="row">
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Role</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            class=""
            style="width: 100%;"
            show-search
            placeholder="Pilih Role"
            :disabled="action === 'Update'"
            option-filter-prop="children"
            v-model="input.role_id"
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="(data, index) in dataroles"
              :value="data.id"
              :key="index"
            >
              {{ data.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Permission</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            class=""
            style="width: 100%;"
            show-search
            placeholder="Pilih permission"
            option-filter-prop="children"
            v-model="input.permission_id"
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="(data, index) in datapermissions"
              :value="data.id"
              :key="index"
            >
              {{ data.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Role Type</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            class=""
            style="width: 100%;"
            show-search
            placeholder="Pilih Role"
            option-filter-prop="children"
            v-model="input.role_type"
            :filter-option="filterOption"
          >
            <a-select-option
              v-for="(data, index) in dataroletype"
              :value="data.id"
              :key="index"
            >
              {{ data.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Batas Bawah</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <cleave
            :options="{ numeral: true }"
            class="ant-input"
            :raw="true"
            v-model="input.batas_bawah"
            @input="(e) => inputChange(e)"
          />
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Batas Atas</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <cleave
            :options="{ numeral: true }"
            class="ant-input"
            :raw="true"
            v-model="input.batas_atas"
            @input="(e) => inputChange(e)"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    cleave: Cleave,
  },
  data() {
    return {
      action: 'Create',
      editdata: [],
      input: {
        permission_id: '',
        role_id: '',
        role_type: '',
        batas_atas: '',
        batas_bawah: '',
      },
      editshiftmode: false,
      datatable: [],
      dataSource: [],
      datapermissions: [],
      dataroles: [],
      dataroletype: [],
      visible: false,
      loading: false,
      datenow: '',
    }
  },
  created() {
    this.datenow = lou.datenow()
  },
  methods: {
    moment,
    inputChange(e) {
      // console.log('e', e)
    },
    async submit() {
      this.loading = true
      var fd = [this.input]
      fd[0].batas_atas = fd[0].batas_atas === '' || fd[0].batas_atas === null ? 0 : parseInt(fd[0].batas_atas)
      fd[0].batas_bawah = fd[0].batas_bawah === '' || fd[0].batas_bawah === null ? 0 : parseInt(fd[0].batas_bawah)
      if (this.action === 'Create') {
        await lou.customUrlPost('otorisasi/rolepermission', fd, true, true)
      } else {
        fd[0].id = this.editdata.id
        fd[0].bpr_id = this.editdata.bpr_id
        fd[0].role_id = this.editdata.role_id
        await lou.customUrlPut('otorisasi/rolepermission', fd, true, true)
      }
      this.loading = false
      this.$parent.getData()
      this.handleCancel()
    },
    async showModal(action, data) {
      var res = await lou.customUrlGet('otorisasi/permission', false, true)
      var res1 = await lou.customUrlGet('otorisasi/roles', false, true)
      var res2 = await lou.customUrlGet('otorisasi/roletype', false, true)
      if (res) {
        this.datapermissions = res.data
      }
      if (res1) {
        this.dataroles = res1.data
      }
      if (res2) {
        this.dataroletype = res2.data
      }
      this.editdata = this.$g.clone(data)
      if (action !== 'Create') {
        // console.log("moment().add(4, 'hours').format('HH:mm')", moment().add(4, 'hours').format('HH:mm'))
        // console.log('this.editdata', this.editdata)
        this.input = this.editdata
        this.editshiftmode = false
      } else {
        this.resetForm()
      }
      this.visible = true
      this.action = action
    },
    addshift() {
      if (
        this.input.shift === null ||
        this.input.hari === null ||
        this.input.datang === null ||
        this.input.pulang === null
      ) {
        lou.shownotif('Input shift, hari, datang, atau pulang masih kosong')
      } else {
        var data = {
          shift: this.input.shift,
          hari: this.input.hari,
          datang: this.input.datang.format('HH:mm'),
          pulang: this.input.pulang.format('HH:mm'),
        }
        if (this.editshiftmode) {
          this.datatable[this.input.editindex] = data
          this.editshiftmode = false
          this.input.editindex = null
        } else {
          this.datatable.push(data)
        }
        this.input.shift = ''
        this.input.hari = 'senin'
      }
    },
    editshift(index, record) {
      this.input.shift = record.shift
      this.input.hari = record.hari
      this.input.datang = moment(record.datang, 'HH:mm')
      this.input.pulang = moment(record.pulang, 'HH:mm')
      this.input.editindex = index
      this.editshiftmode = true
    },
    deleteshift(index) {
      // console.log('index', index)
      this.datatable.splice(index, 1)
    },
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    resetForm() {
      this.action = 'Create'
      this.editdata = []
      this.input =
        {
          permission_id: '',
          role_id: '',
          role_type: '',
          batas_atas: '',
          batas_bawah: '',
        }

      this.datatable = []
      this.editshiftmode = false
      this.visible = false
      this.loading = false
      this.datenow = ''
    },
    onSearch(searchText) {
      this.dataSource = !searchText ? [] : ['Pagi', 'Sore', 'Damn']
    },
    handleChange(value) {
      // console.log(`selected ${value}`)
    },
    handleBlur() {
      // console.log('blur')
    },
    handleFocus() {
      // console.log('focus')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    capt(word) {
      return lou.capitalize(word)
    },
  },
}
</script>

<style></style>
